import { ref } from 'vue'
import Tariff from '@/models/Tariff'
import { useLoading } from '@/composables/useLoading'

export default function TariffsController () {
  const { loading, loadingOn, loadingOff } = useLoading()

  const tariffs = ref([])

  const mess = ref(null)
  const messVisible = ref(false)

  const getTariffs = async () => {
    loadingOn()
    const response = await fetch('https://valeriya-artist-admin.ru/api/tariffs', {
      method: 'GET',
      headers: {
        Authorization: localStorage.getItem('admin_token')
      }
    })
    if (response.ok) {
      const res = await response.json()
      if (!res.mess) tariffs.value = Array.isArray(res) ? res.map((o) => new Tariff(o)) : res
      loadingOff()
    }
  }

  const currentTariff = ref(null)
  const getTariff = async (id) => {
    loadingOn()
    const response = await fetch(`https://valeriya-artist-admin.ru/api/tariffs/${id}`, {
      headers: {
        Authorization: localStorage.getItem('admin_token')
      }
    })
    if (response.ok) {
      const res = await response.json()
      currentTariff.value = new Tariff(res)
      loadingOff()
    }
  }

  const tariffsApiWrapper = async (url, payload) => {
    loadingOn()
    const response = await fetch(`https://valeriya-artist-admin.ru/api/tariffs/${url}`, {
      method: 'POST',
      headers: {
        Authorization: localStorage.getItem('admin_token')
      },
      body: JSON.stringify(payload)
    })
    if (response.ok) {
      const res = await response.json()
      mess.value = res?.mess
      messVisible.value = true
      getTariffs()
      setTimeout(() => (messVisible.value = false), 2000)
    }
  }

  const updateTariff = async (payload) => {
    loadingOn()
    const response = await fetch('https://valeriya-artist-admin.ru/api/tariffs/tariff', {
      method: 'POST',
      headers: {
        Authorization: localStorage.getItem('admin_token')
      },
      body: JSON.stringify(payload)
    })
    if (response.ok) {
      const res = await response.json()
      mess.value = res?.mess
      messVisible.value = true
      getTariff(currentTariff.value.id)
      setTimeout(() => (messVisible.value = false), 2000)
    }
  }

  return {
    getTariffs,
    tariffs,

    getTariff,
    currentTariff,

    tariffsApiWrapper,
    updateTariff,
    mess,
    messVisible,
    loading
  }
}
