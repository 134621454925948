export default class Paint {
  constructor (data) {
    this.id = data?.id
    this.price = data?.price ?? null
    this.link = data?.link ?? null
    this.code = data?.code ?? null
    this.name = data?.name ?? null
    this.description = data?.description ?? false
  }
}
