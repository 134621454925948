import { ref } from 'vue'
import Other from '@/models/Other'
import { useLoading } from '@/composables/useLoading'

export default function OtherController () {
  const { loading, loadingOn, loadingOff } = useLoading()

  const other = ref(null)
  const mess = ref(null)
  const messVisible = ref(false)

  const getOther = async () => {
    loadingOn()
    const response = await fetch('https://valeriya-artist-admin.ru/api/other', {
      headers: {
        Authorization: localStorage.getItem('admin_token')
      }
    })
    if (response.ok) {
      const res = await response.json()
      other.value = res.map(o => new Other(o))
      loadingOff()
    }
  }

  const otherApiWrapper = async (url, payload) => {
    loadingOn()
    const response = await fetch(`https://valeriya-artist-admin.ru/api/other/${url}`, {
      method: 'POST',
      headers: {
        Authorization: localStorage.getItem('admin_token')
      },
      body: JSON.stringify(payload)
    })
    if (response.ok) {
      const res = await response.json()
      mess.value = res?.mess
      messVisible.value = true
      getOther()
      setTimeout(() => (messVisible.value = false), 2000)
    }
  }

  return {
    getOther,
    other,

    otherApiWrapper,
    mess,
    messVisible,
    loading
  }
}
