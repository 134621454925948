import { ref } from 'vue'
import Module from '@/models/Module'
import { useLoading } from '@/composables/useLoading'

export default function CoursesController () {
  const { loading, loadingOn, loadingOff } = useLoading()

  const courses = ref([])
  const coursesDescription = ref({})

  const mess = ref(null)
  const messVisible = ref(false)
  const setMess = (err) => {
    mess.value = err
    messVisible.value = true
    setTimeout(() => (messVisible.value = false), 2000)
  }

  const error = ref(null)
  const setError = (err) => {
    error.value = err
    setTimeout(() => (error.value = ''), 3000)
  }

  const getCourses = async () => {
    loadingOn()
    const response = await fetch('https://valeriya-artist-admin.ru/api/courses', {
      method: 'GET',
      headers: {
        Authorization: localStorage.getItem('admin_token')
      }
    })
    const res = await response.json()
    if (response.ok) {
      courses.value = res.modules.map((o) => new Module(o))
      coursesDescription.value = res.modules_description
    } else setError(res.err)
    loadingOff()
  }

  const coursesApiWrapper = async (url, payload) => {
    loadingOn()
    const response = await fetch(`https://valeriya-artist-admin.ru/api/${url}`, {
      method: 'POST',
      headers: {
        Authorization: localStorage.getItem('admin_token')
      },
      body: JSON.stringify(payload)
    })
    const res = await response.json()
    if (response.ok) {
      setMess(res.mess)
      getCourses()
    } else setError(res.err)
    loadingOff()
  }

  const changeModuleIcon = async (url, payload) => {
    loadingOn()
    const response = await fetch(`https://valeriya-artist-admin.ru/api/modules/${url}`, {
      method: 'POST',
      headers: {
        Authorization: localStorage.getItem('admin_token')
      },
      body: payload
    })
    const res = await response.json()
    if (response.ok) {
      setMess(res.mess)
      getCourses()
    } else setError(res.err)
    loadingOff()
  }

  return {
    getCourses,
    courses,
    coursesDescription,
    coursesApiWrapper,
    changeModuleIcon,
    mess,
    messVisible,
    error,
    loading
  }
}
