import { ref } from 'vue'
import Paint from '@/models/Paint'
import { useLoading } from '@/composables/useLoading'

export default function CatalogController () {
  const { loading, loadingOn, loadingOff } = useLoading()

  const currentPaint = ref(null)

  const mess = ref(null)
  const messVisible = ref(false)

  const getProduct = async (id) => {
    loadingOn()
    const response = await fetch(`https://valeriya-artist-admin.ru/api/catalog/${id}`, {
      headers: {
        Authorization: localStorage.getItem('admin_token')
      }
    })
    if (response.ok) {
      const res = await response.json()
      currentPaint.value = new Paint(res)
      loadingOff()
    }
  }

  const paintApiWrapper = async (url, payload) => {
    loadingOn()
    const response = await fetch(`https://valeriya-artist-admin.ru/api/painting/${url}`, {
      method: 'POST',
      headers: {
        Authorization: localStorage.getItem('admin_token')
      },
      body: JSON.stringify(payload)
    })
    if (response.ok) {
      const res = await response.json()
      mess.value = res?.mess
      messVisible.value = true
      getProduct(currentPaint.value.id)
      setTimeout(() => (messVisible.value = false), 2000)
    }
  }

  const imageApiWrapper = async (url, payload) => {
    loadingOn()
    const response = await fetch(`https://valeriya-artist-admin.ru/api/images/${url}`, {
      method: 'POST',
      headers: {
        Authorization: localStorage.getItem('admin_token')
      },
      body: JSON.stringify(payload)
    })
    if (response.ok) {
      const res = await response.json()
      mess.value = res?.mess
      messVisible.value = true
      getProduct(currentPaint.value.id)
      setTimeout(() => (messVisible.value = false), 2000)
    }
  }

  const addImage = async (payload) => {
    loadingOn()
    const response = await fetch('https://valeriya-artist-admin.ru/api/images/add', {
      method: 'POST',
      headers: {
        Authorization: localStorage.getItem('admin_token')
      },
      body: payload
    })
    if (response.ok) {
      const res = await response.json()
      mess.value = res?.mess
      messVisible.value = true
      getProduct(currentPaint.value.id)
      setTimeout(() => (messVisible.value = false), 2000)
    }
  }

  return {
    getProduct,
    currentPaint,

    paintApiWrapper,
    imageApiWrapper,
    addImage,
    mess,
    messVisible,
    loading
  }
}
