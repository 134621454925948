import UiSvgIcon from './UiSvgIcon'
import UiButton from './UiButton'
import UiDropdown from './UiDropdown'
import UiInput from './UiInput'
import UiTextarea from './UiTextarea'
import UiSelect from './UiSelect'
import UiFullScreenLoader from './UiFullScreenLoader'
import UiBaseLoader from './UiBaseLoader'
import UiClose from './UiClose'
import UiBurgerMenuIcon from './UiBurgerMenuIcon'
import UiDelete from './UiDelete'
import UiAdd from './UiAdd'
import UiMove from './UiMove'
import UiCheckbox from './UiCheckbox'

export default function initializeUiKit (app) {
  app.component('UiSvgIcon', UiSvgIcon)
  app.component('UiButton', UiButton)
  app.component('UiDropdown', UiDropdown)
  app.component('UiInput', UiInput)
  app.component('UiTextarea', UiTextarea)
  app.component('UiSelect', UiSelect)
  app.component('UiFullScreenLoader', UiFullScreenLoader)
  app.component('UiBaseLoader', UiBaseLoader)
  app.component('UiClose', UiClose)
  app.component('UiBurgerMenuIcon', UiBurgerMenuIcon)
  app.component('UiDelete', UiDelete)
  app.component('UiAdd', UiAdd)
  app.component('UiMove', UiMove)
  app.component('UiCheckbox', UiCheckbox)
}
