import { ref } from 'vue'
import Paint from '@/models/Paint'
import { useLoading } from '@/composables/useLoading'

export default function CatalogController () {
  const { loading, loadingOn, loadingOff } = useLoading()

  const catalog = ref([])

  const mess = ref(null)
  const messVisible = ref(false)

  const getCatalog = async () => {
    loadingOn()
    const response = await fetch('https://valeriya-artist-admin.ru/api/catalog', {
      method: 'GET',
      headers: {
        Authorization: localStorage.getItem('admin_token')
      }
    })
    if (response.ok) {
      const res = await response.json()
      if (!res.mess) catalog.value = res.map((o) => new Paint(o))
      loadingOff()
    }
  }

  const catalogApiWrapper = async (url, payload) => {
    loadingOn()
    const response = await fetch(`https://valeriya-artist-admin.ru/api/painting/${url}`, {
      method: 'POST',
      headers: {
        Authorization: localStorage.getItem('admin_token')
      },
      body: JSON.stringify(payload)
    })
    if (response.ok) {
      const res = await response.json()
      mess.value = res?.mess
      messVisible.value = true
      getCatalog()
      setTimeout(() => (messVisible.value = false), 2000)
    }
  }

  return {
    getCatalog,
    catalog,

    catalogApiWrapper,
    mess,
    messVisible,
    loading
  }
}
