import { ref, computed } from 'vue'
import store from '@/store'

export default function AdminController () {
  const adminToken = ref(localStorage.getItem('admin_token') || '')
  const errMess = ref('')

  const isAuth = computed(() => adminToken.value)

  const auth = async payload => {
    if (!payload) return
    const response = await fetch('https://valeriya-artist-admin.ru/api/auth', {
      method: 'POST',
      body: payload
    })
    const res = await response.json()
    if (response.ok) {
      errMess.value = ''
      localStorage.setItem('admin_token', res.admin_token)
      adminToken.value = res.admin_token
      store.modalQueue.removeAll()
    } else errMess.value = res.err
  }

  const login = async payload => {
    if (!payload) return
    const response = await fetch('https://valeriya-artist-admin.ru/api/login', {
      method: 'POST',
      body: payload
    })
    const res = await response.json()
    if (response.ok) auth(payload)
    else errMess.value = res.err
  }

  const logout = async () => {
    localStorage.removeItem('admin_token')
    adminToken.value = ''
  }

  return {
    auth,
    isAuth,
    login,
    logout,
    errMess
  }
}
