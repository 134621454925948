import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store, { initStore } from '@/store'

import '@/_core/styles/reset.css'
import '@/assets/styles/general.sass'
import initializeUiKit from '@/components/_ui'

initStore()
const app = createApp(App)
initializeUiKit(app)
app
  .use(router)
  .provide('store', store)
  .mount('#app')
