import { reactive } from 'vue'
import ModalQueueController from '@/controllers/ModalQueueController'
import AdminController from '@/controllers/AdminController'
import CoursesController from '@/controllers/admin/CoursesController'
import CatalogController from '@/controllers/admin/CatalogController'
import PaintController from '@/controllers/admin/PaintController'
import TariffsController from '@/controllers/admin/TariffsController'
import OtherController from '@/controllers/admin/OtherController'

const store = reactive({})

export default store

export function initStore () {
  store.modalQueue = ModalQueueController()
  store.admin = AdminController()
  store.courses = CoursesController()
  store.catalog = CatalogController()
  store.paint = PaintController()
  store.tariffs = TariffsController()
  store.other = OtherController()
}
